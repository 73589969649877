import { Injectable } from '@angular/core';
import { TokenEnum } from '@core/enums/token.enum';
import { GraphQLResponse, TokensResponse } from '@core/interfaces/auth.interface';
import { Apollo, gql } from 'apollo-angular';
import {
  map, Observable, of, tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class TokenService {
  constructor(private readonly apollo: Apollo) { }

  public setRefreshToken(refreshToken?: string): void {
    if (refreshToken) {
      localStorage.setItem(TokenEnum.refreshToken, refreshToken);
    } else {
      localStorage.removeItem(TokenEnum.refreshToken);
    }
  }

  public setAccesstToken(acccessToken?: string): void {
    if (acccessToken) {
      localStorage.setItem(TokenEnum.accessToken, acccessToken);
    } else {
      localStorage.removeItem(TokenEnum.accessToken);
    }
  }

  public getAccessToken(): Observable<string | null> {
    const token = localStorage.getItem(TokenEnum.accessToken);
    return of(token);
  }

  public getAccess(): string | null {
    const token = localStorage.getItem(TokenEnum.accessToken);
    return token;
  }

  public refreshToken(): Observable<TokensResponse> {
    const token = localStorage.getItem(TokenEnum.refreshToken);
    return this.apollo.mutate<{ refresh: TokensResponse }>({
      mutation: gql`
        mutation refresh($token: String!) {
          refresh(token: $token) {
              accessExpiresIn
              accessToken
              refreshExpiresIn
              refreshToken
          }
        }
      `,
      variables: {
        token,
      },
    }).pipe(
      map((response) => response.data!.refresh),
      tap((tokens) => {
        if (tokens) {
          localStorage.setItem(TokenEnum.accessToken, tokens.accessToken);
          localStorage.setItem(TokenEnum.refreshToken, tokens.refreshToken);
        }
      }),
    );
  }
}
